/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/1
 */
@import "variable";
@import "mixin";
.small-font {
    font-size: 1em;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.90)
}

.radio-item, .checkbox-item {
    float: left;
    white-space: nowrap;
    margin-right: 5px;
    label {
        padding: 0 !important;
        margin: 0 !important;
        position: relative;
        cursor: pointer;
        height: 100%;
        display: inline-block;
        float: left;
        .text {
            display: inline-block;
        }
    }
    input {
        display: none !important;
        width: unset;
    }
    .radio-bg {
        display: inline-block;
        height: 14px;
        width: 14px;
        margin-right: 1px;
        padding: 0;
        background-color: white;
        border-radius: 100%;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1) inset, 0 1px 4px rgba(0, 0, 0, 0.1) inset, 1px -1px 2px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: all 0.5s ease;
    }

    input:checked + span.radio-on {
        width: 9px;
        height: 9px;
        position: absolute;
        left: 3px;
        top: 9px;
        border-radius: 100%;
        box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3), 0 0 1px rgba(255, 255, 255, 0.4) inset;
        background-image: linear-gradient(#{$FORM_ICON_BG_COLOR} 0, #{$FORM_ICON_BG_COLOR} 100%);
        transition: all 0.5s ease;

    }
    &:hover {
        border: none;
    }
}

.checkbox-item {
    .radio-bg {
        border-radius: 10% !important;
    }
    input:checked + span.radio-on {
        border-radius: 10% !important;
    }
}

.jgp-form, .jgp-query {
    width: 100%;
    overflow: hidden;
    .jgp-btn{
        margin-top:5px;
    }
    .jgp-form-group {
        float: left;
        > .group-top {
            margin: 8px 5px 2px;
            padding: 5px;
            /* top
            border-top: 4px solid $FORM_GROUP_TITLE_BORDER_COLOR;
            background-color: $FORM_GROUP_TITLE_BG_COLOR;
            */
            border-left: 4px solid $FORM_GROUP_TITLE_BORDER_COLOR;
            background-color: $FORM_GROUP_TITLE_BG_COLOR;
            color: #263238;
            > .group-title {
                padding-left: 5px;
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
        }
        > .group-bottom {
        }
    }

    &.label-align-left,&.label-align-center,&.label-align-right{
        .label-wrap{
            padding: 0 5px;
        }
    }
    &.label-align-center{
        .label-wrap{
            text-align: center;
        }
    }
    &.label-align-right{
        .label-wrap{
            text-align: right;
            label {
                b{
                    right: 0px;
                }
            }
        }
    }
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #b7b7b7;
    font-size: 0.9em;
}

input::-moz-placeholder, textarea::-webkit-input-placeholder { /* Mozilla Firefox 19+ */
    color: #b7b7b7;
    font-size: 0.9em;
}

input:-moz-placeholder, textarea::-webkit-input-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #b7b7b7;
    font-size: 0.9em;
}

input:-ms-input-placeholder, textarea::-webkit-input-placeholder { /* Internet Explorer 10-11 */
    color: #b7b7b7;
    font-size: 0.9em;
}

.inline-control {
    float: left;
    input, label, textarea {
        outline: none;
        border: none;
        display: block;
        padding: 0 2px;
        font-size: .9em;
        height: 100%;
        width: 100%;
        padding-left: 2px;
        padding-right: 2px;
        background-color: transparent;
        color: inherit;
        //@include calc('width', '100% - 4px', 'true');
    }
    label {
        padding-top: 1px;
        padding-bottom: 1px;
        b{
            color: red;
            position: absolute;
            left: 0px;
            top: 0px;
        }
    }

    .label-wrap, .input-wrap, .textarea-wrap, .tags-wrap {
        height: $FORM_INPUT_HEIGHT;
        line-height: calc(#{$FORM_INPUT_HEIGHT} - 3px);
        text-align: left;
        float: left;
        position: relative;
    }
    .input-wrap, .textarea-wrap, .tags-wrap {
        border: 1px solid #d9d9d9;
        border-top: 1px solid #c0c0c0;
        &.is-danger {
            -webkit-box-shadow: 0 0 2px $STATE_DANGER_COLOR !important;
            -moz-box-shadow: 0 0 2px $STATE_DANGER_COLOR !important;
            box-shadow: 0 0 2px $STATE_DANGER_COLOR !important;
            border: 1px solid $STATE_DANGER_COLOR !important;
        }
        &:hover {
            border: 1px solid #45bcb8;
            border-top: 1px solid #45bcb8;
            -webkit-box-shadow: 0 0 2px rgba(21, 144, 107, 0.24);
            -moz-box-shadow: 0 0 2px rgba(21, 144, 107, 0.24);
            box-shadow: 0 0 2px rgba(21, 144, 107, 0.24);
        }
        &.focus {
            outline: none;
            -webkit-box-shadow: 0 0 2px rgba(76, 143, 254, 0.57);
            -moz-box-shadow: 0 0 2px rgba(76, 143, 254, 0.57);
            box-shadow: 0 0 2px rgba(76, 143, 254, 0.57);
            border: 1px solid #45bcb8;
        }

        .icon-wrap, .tool-wrap {
            position: absolute;
            top: 0;
            bottom: 0;
            .icon-label, .tool-label {
                display: block;
                text-align: center;
                height: 100%;
                width: 18px;
                i, b {
                    line-height: $FORM_INPUT_HEIGHT;
                    text-align: center;
                    color: #45bcb8;
                }
            }
            .icon-content {
                padding: 5px;
                min-width: 80px;
                letter-spacing: 3px;
            }
        }

        .tool-wrap {
            right: 0;
            height: 100%;
        }

    }

    .textarea-wrap {
        height: 100%;
    }
    .textarea-wrap {
        textarea {
            height: 100%;
            resize: none;
        }
    }
    .tags-wrap {
        outline: none;
        min-height: $FORM_INPUT_HEIGHT;
        .tag {
            float: left;
            margin: 2px;
            background-color: $FORM_INPUT_FOCUS_BORDER_COLOR;
            line-height: calc(#{$FORM_INPUT_HEIGHT} - 7px);
            a {
                color: white;
                padding: 0px 4px;
                display: block;
                font-size: .7em;
            }
        }
    }
    .operate-wrap {
        position: absolute;
        right: 0;
        top: 0;
        .button {
            display: block;
            background-color: #e4e4e4;
            cursor: pointer;
            height: $FORM_INPUT_HEIGHT;
            line-height: calc(#{$FORM_INPUT_HEIGHT} - 3px);
        }
    }

    &.disabled {
        .input-wrap,.textarea-wrap {
            background-color: $FORM_INPUT_DISABLED_COLOR;
            input, textarea,label {
                cursor: default;
            }
        }

    }
    &.readonly {
        .input-wrap {
            background-color: $FORM_INPUT_READONLY_COLOR;
            input, textarea,label {
                cursor: default;
            }
        }
    }

    &.jgp-radio, &.jgp-checkbox {
        .input-wrap {
            border: none;
        }
    }
    &.jgp-checkbox {

    }

    &.jgp-drop {
        //样式在_tip中
    }
}

.popover{
    .radio{
        .radio-on{
            top:8px!important;
        }
    }
}

.jgp-date-time-panel {
    overflow: hidden;
    @include calc('max-height', '#{$DATE_TIME_PANEL_HEAD_HEIGHT} + #{$DATE_TIME_PANEL_CONTENT_HEIGHT}');
    > .header {
        height: $DATE_TIME_PANEL_HEAD_HEIGHT;
        table {
            width: 100%;
            padding: 0;
            border: 0;
        }
        background-color: $DATE_TIME_PANEL_HEAD_BG_COLOR;
        color: $DATE_TIME_PANEL_HEAD_COLOR;
        a {
            text-align: center;
            i {
                padding: 15px 15px;
            }
            display: block;
            color: white;
            &:hover {
                background-color: $DATE_TIME_PANEL_HEAD_BTN_BG_HOVER;
            }
        }
        span.current {
            width: 100px;
            display: block;
            text-align: center;
            cursor: pointer;
        }
    }
    .body {
        .date-panel, .month-panel {
            height: $DATE_TIME_PANEL_CONTENT_HEIGHT;
        }
        .button {
            span {
                font-weight: bold;
                color: $DATE_TIME_PANEL_HEAD_BG_COLOR;
            }

        }
        .date-panel {
            padding-bottom: 20px;
            .weeks, .dates {
                margin: auto;
                text-align: center;
                span {
                    display: inline-block;
                    width: 30px;
                    height: 26px;
                    line-height: 26px;
                    text-align: center;
                    font-size: 0.9em;
                }
                .column {
                    cursor: pointer;
                    color: #c3c0c0;

                    &.prev-month {
                        cursor: not-allowed
                    }
                    &.next-month {
                        cursor: not-allowed
                    }

                    &.normal {
                        color: inherit;
                        &:hover {
                            background-color: rgba(140, 202, 255, 0.54);
                            color: white;
                        }
                    }
                    &.select {
                        background-color: $DATE_TIME_PANEL_HEAD_BG_COLOR;
                        color: white;
                    }
                    &.today {
                        background-color: #d4d3d3;
                        color: white;
                    }
                }
            }
        }
        .time-picker {
            position: absolute;
            width: 100%;
            text-align: left;
            padding: 0px 22px;
            bottom: 5px;
            > span {
                icon {
                    font-weight: normal;
                    -webkit-font-smoothing: antialiased; /*chrome、safari*/
                    -moz-osx-font-smoothing: grayscale; /*firefox*/
                }
                display: inline-block;
                input {
                    height: 20px;
                    background-color: #7e8c8d;
                    color: white;
                    text-align: center;
                }
                input.h {
                    width: 30px;
                }
                input.m {
                    width: 30px;
                }
            }
        }
        .month-panel {
            padding-bottom: 30px;
            .select-year, .select-month {
                float: left;
                width: 50%;
                height: 100%;
                div.normal {
                    font-size: 1.3em;
                    padding: 16px 0;
                    text-align: center;
                    cursor: pointer;
                    transition: font-size .3s;
                    &:hover {
                        font-size: 2em;
                    }
                }
                div.select {
                    padding: 16px 0;
                    text-align: center;
                    font-size: 2em;
                    color: $DATE_TIME_PANEL_HEAD_BG_COLOR;
                    cursor: default;
                }

            }
        }

        .time-panel {
            height: 80px;
            width: 150px;
            overflow: hidden;
            .select-hours, .select-minutes {
                width: 40%;
                float: left;
                height: 100%;
                div.normal {
                    font-size: 1.2em;
                    padding: 6px 0;
                    text-align: center;
                    cursor: pointer;
                    transition: font-size .3s;
                    &:hover {
                        font-size: 2em;
                    }
                }
                div.select {
                    padding: 6px 0;
                    text-align: center;
                    font-size: 2em;
                    color: $DATE_TIME_PANEL_HEAD_BG_COLOR;
                    cursor: default;
                }
            }
        }
        .single-month-panel {
            height: 80px;
            width: 100px;
            overflow: hidden;

            .select-single-month {
                width: 60%;
                float: left;
                height: 100%;
                div.normal {
                    font-size: 1.2em;
                    padding: 6px 0;
                    text-align: center;
                    cursor: pointer;
                    transition: font-size .3s;
                    &:hover {
                        font-size: 2em;
                    }
                }
                div.select {
                    padding: 6px 0;
                    text-align: center;
                    font-size: 2em;
                    color: $DATE_TIME_PANEL_HEAD_BG_COLOR;
                    cursor: default;
                }
            }
        }

        .bottom-buttons {
            position: absolute;
            right: 5px;
            bottom: 2px;
        }
        .right-buttons {
            position: absolute;
            top: 0;
            right: 5px;
            .button {
                margin-top: 5px;
                position: relative;
                float: left;
                clear: both;
            }
        }

    }

}
