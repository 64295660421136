/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
@import "variable";

.jgp-grid {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5px;
    white-space: nowrap;
    clear: both;
    .head, .grid {
        clear: both;
        min-width: 100%;
    }
    .x-scroll-wrap {
        min-width: 100%;
    }

    .head {
        position: relative;
        overflow: hidden;
        width: 9999px;
        background: #eee;
        line-height: $GRID_HEIGHT;

        @include linear-gradient-y($GRID_HEAD_BG_COLOR_FROM, $GRID_HEAD_BG_COLOR_TO);

        -moz-box-shadow: -2px -1px 20px 4px #263238;
        -webkit-box-shadow: -2px -1px 20px 4px #263238;
        box-shadow: -2px -1px 20px 4px #263238;
        z-index: 1;
    }

    .check-wrap, .index-wrap, .operator-column {
        display: table;
        text-align: center;
    }

    .wrap {
        /*border: 1px solid $GRID_BORDER_COLOR;*/
        border-bottom: none;
    }
    .pager {
        height: 100%;
    }

    .check {
        position: relative;
        cursor: pointer;
        display: table-cell;
        input {
            display: none;
            width: unset;
        }
        .radio-bg {
            display: inline-block;
            position: relative;
            height: 10px;
            width: 10px;
            margin-right: 5px;
            padding: 0;
            border-radius: 20%;
            box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1) inset, 0 1px 4px rgba(0, 0, 0, 0.1) inset, 1px -1px 2px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            transition: all 0.5s ease;
        }

        input:checked + span.radio-bg {
            border-radius: 50%;
            box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3), 0 0 1px rgba(255, 255, 255, 0.4) inset;
            background-image: linear-gradient(white 0, #00da07 100%);
            transition: all 0.5s ease;
            top: -1px;
        }
    }

    .column {
        cursor: pointer;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: $GRID_HEIGHT;
        padding: 0 8px;
        border-left: 1px solid white;
        &:first-child{
            border-left: none;
        }
        > span, > label {
            line-height: $GRID_HEIGHT;
        }
        .jgp-grid-col {
            height: $GRID_HEIGHT;
            line-height: $GRID_HEIGHT;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span.underline {
                border-bottom: 1px solid #bbb;
            }
        }
    }
    .head .column {
        .category {
            display: table;
            width: 100%;
            height: 100%;
            color: white;
            text-align: center;
            > span, > label {
                display: table-cell;
                font-weight: bold;
                font-size: 1em;
            }
            .check {
                display: table-cell;
                .radio-bg {
                    background-color: #ffffff;
                }
            }
        }
    }
    .grid .column {
        border-right: 1px dashed rgba(88, 196, 191, 0.19);
        &.operator-column {
            .operator-wrap {
                display: table-cell;
                .grid-tool {
                    display: inline-block;
                    margin: 0 5px;
                }
            }

        }
    }
    .grid .grid-row:nth-child(odd) {
        background-color: rgba(188, 191, 191, 0.19);
    }

    .grid .grid-row[click-state=true] {
        @include linear-gradient-y(#607d8b, #3b973c);
        .column {
            span, a.grid-tool {
                color: white;
                font-weight: bold;
            }
        }
    }

    .grid .grid-row:hover {
        background-color: rgba(26, 154, 247, 0.31);
    }
}

.jgp-list {
    width: 100%;
    height: 100%;
    .body {
        width: 100%;
        height: 100%;
        position: relative;
        > ul {
            li {
                padding: 5px 0px;
                padding-bottom: 0px;
                div.item {
                    padding: 8px;
                    display: block;
                    position: relative;
                    .title {
                        font-size: 1em;
                        font-weight: bold;
                    }
                    .content {
                        font-size: .9em;
                    }
                    .bg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    .remove {
                        position: absolute;
                        top: 1px;
                        right: 5px;
                        color: #a7a7a7;
                        font-size: 1.2em;
                    }
                    .remove:hover {
                        color: brown;
                        font-size: 1.5em;
                    }
                }
            }
        }

        .drop-area {
            position: absolute;
            height: 100px;
            border: 5px dashed rgba(0, 0, 0, 0.16862745098039217);
            left: 10%;
            right: 10%;
            z-index: -1;
            @include calc('top', '(100% - 100px)/2', 'false');

            &.hover {
                border: 5px dashed rgba(0, 0, 0, 0.3);
            }

            > table {
                width: 100%;
                height: 100%;
                text-align: center;
                font-size: 1.5em;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.16862745098039217);
            }
        }
    }
}
