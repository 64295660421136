/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/20
 */
@import "variable";

.tooltip {
    display: block !important;
    &.popover {

        .popover-inner {
            background: $TIP_BG_COLOR;
            opacity: 0.9;
            filter: alpha(opacity=90);
            color: black;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, .1);
        }

        .popover-arrow {
            border-color: $TIP_ARROW_COLOR;
        }
    }

    .tooltip-inner {
        font-size: .9em;
        background: black;
        color: white;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
    }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }
}

.tooltip {
    &.is-danger {
        .tooltip-inner {
            background-color: #ff0800ad;
        }
        .tooltip-arrow {
            border-color: #ff0800ad;
        }
    }

    &.grid-tip-info{
        .tooltip-inner {
            background-color: rgb(255, 255, 255);
        }
        .tooltip-arrow {
            border-color: #b2b2b2;
        }
        .tooltip-inner{
            color: black;
            border: 1px solid #eae9e9;
        }

    }

    &.form-drop {
        z-index: 10;
        min-width: 100px;
        .popover-arrow {
            border-color: $TIP_FORM_DROP_ARROW_COLOR;
        }
        .popover-inner {
            padding: 0px !important;
            border: 1px solid $TIP_FORM_DROP_BORDER_COLOR;
        }

        &.select-drop {
            .drop-content {
                overflow: hidden;
                margin: 10px 0;
                padding: 0 10px;
                > div {
                    div.select, div.normal {
                        text-align: left;
                        height: 20px;
                        border-bottom: 1px dashed #bdbdbd;
                        white-space: nowrap;
                    }
                    div.normal {
                        cursor: pointer;
                        transition: font-size .2s;
                    }
                    div.select {
                        color: $TIP_FORM_DROP_BORDER_COLOR;
                        cursor: default;
                    }
                }
                .radio,.text{
                    word-spacing: normal;
                    white-space: nowrap;
                }
                .checkbox-item{
                float: unset!important;
                    label{
                        width: 100%;
                    }
                }
            }
            input[type=text].search{
                outline: none;
                border: none;
                display: block;
                padding: 0 2px;
                font-size: 1em;
                height: 100%;
                width: 100%;
                padding-left: 2px;
                padding-right: 2px;
                background-color: transparent;
                border-bottom: 1px solid $TIP_FORM_DROP_BORDER_COLOR;
                height: 30px;
                padding: 0 20px;
            }
        }
    }
}




