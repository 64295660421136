/**
 * 项目   jgp-front-pc
 * 作者   loufei
 * 时间   2019/3/21
 */
@import "variable";
@import "mixin";

.jgp-adv-user-info {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction:row;
	justify-content : flex-end;
	align-items : center; 
	user-select: none;
	.tips{
		float: right;
		margin-right: 10px;
		color: #ECEFF1;
		white-space: nowrap;
		> span{
			cursor: pointer;
			position: relative;
			float: left;
			margin-right: 10px;
			&::before{
				content: attr(data-num) ;
				position: absolute;
				font-size: 11px !important;
				font-weight: 300;
				top: -11px;
				text-align: center;
				height: 14px;
				line-height: 14px;
				background-color: #e02222;
				-webkit-border-radius: 12px !important;
				-moz-border-radius: 12px !important;
				border-radius: 12px !important;
				text-shadow: none !important;
				padding: 1px 6px;
			}
		}
	}
    .imghead {
		float: right;
        text-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 50%;
            -webkit-border-radius: 50%;
			-moz-border-radius: 50%;
        }
        .account{
            color: white;
        }
    }

    .drop-panel{
        position: absolute;
        background-color: white;
        height: auto;
        width: 230px;
        top:0;
        padding: 10px;
        box-shadow: 1px 1px 20px 0px #607d8a;
        .imghead {
			float: unset;
			display: flex;
			flex-direction:row;
			justify-content : center;
			align-items : center; 
            .account{
                color: inherit;
            }
        }
        ul{
            li{
                margin: 6px 0;
                clear: both;
                text-align: center;
                @extend .clearfix;
                &:last-child{
                }
                &.text{
                    color: #525252;
                    text-align: left;
                    span{
                        display: block;
                        float: left;
                        &:first-child{
                            font-weight: bold;
                        }
                    }
                }
                &.tool{
                    cursor: pointer;
                    span{
                        display: inline-block;
                    }
                    span:first-child{
                        width: 30px;
                        height: 100%;
                        text-align: center;
                    }
                    span:last-child{
                        @include calc('width', '100% - 30px', 'false');
                        height: 100%;
                        text-align: left;
                    }
                }
            }
        }
    }

}
