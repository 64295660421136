/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
@import "variable";
@import "mixin";
.jgp-menu[theme=left] {
    background-color: #607d8b;
    min-height: 100%;
    span.text {
        display: block;
        width: 100%;
        color: white;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .jgp-menu-item {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.09);
        .tree-line-node{
            &[level="1"]{
                span.text {
                    padding: 8px 0;
                    text-indent: 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
                }
            }

            &[level="2"] {
                padding: 4px 0;
                span.text {
                    padding-left: 20px;
                    text-indent: 10px;
                }
            }

            &[level="3"] {
                > span.text {
                    padding: 4px 0px;
                    padding-left: 20px;
                    text-indent: 15px;
                }
            }

            &[level="4"] {
                > span.text {
                    padding: 4px 0px;
                    padding-left: 20px;
                    text-indent: 30px;
                }
            }
            &.checked{
                background-color: #00a2d4;
                > span.text {
                    font-size: 1.2em;
                }
            }
        }

    }





}
