/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
.jgp-tools{
    margin: 5px;
    .btn-wrap{
        display: table-row;
        .jgp-btn,.button{
            display: table-cell;
        }
    }
}
