/**
 * 项目   skin
 * 作者   loufei
 * 时间   2018/4/24
 */
$doc-types:doc docx pdf xls xlsx txt mp4 avi mp3 wav zip rar iso;
.jgp-form{
    .jgp-uploader{
        float: left;
        width: 100%;
        overflow: hidden;
    }
}
.jgp-uploader {
    padding: 7px;
    margin: 1px 0;
    background-color: #fafafa;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, .05);
    border-color: #e5e5e5 #eee #eee;
    border-style: solid;
    border-width: 1px 0;

    .webuploader-container {
        position: relative;
    }
    .webuploader-element-invisible {
        position: absolute !important;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
    }
    .webuploader-pick {
        cursor: pointer;
        padding: 10px 15px;
        text-align: center;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        + div {
            height: 100% !important;
            width: 100% !important;
            top: 0 !important;
            label {
                height: 100%;
                width: 100%;
            }
        }

    }

    .webuploader-pick-disable {
        opacity: 0.6;
        pointer-events: none;
    }
    .element-invisible {
        position: absolute !important;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
    }
    .uploader {
        border: 3px dashed #e6e6e6;
        position: relative;
        &.webuploader-dnd-over {
            border-color: #999999;
            &.webuploader-dnd-denied {
                border-color: red;
            }
        }

        .queueList:nth-child(1) {
            background-color: beige;
        }
        .placeholder {
            padding-top: 17%;
            text-align: center;
            color: #cccccc;
            font-size: 1.3em;
            position: relative;
            background: url(/public/plugin/uploader/file.png) center 16% no-repeat;
        }
        .placeholder:hover {
            background: url(/public/plugin/uploader/file_hover.png) center 16% no-repeat;
        }

        .filelist {
            list-style: none;
            margin: 0;
            padding: 0;
            &:after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                overflow: hidden;
                clear: both;
            }

            li {
                width: 111px;
                height: 134px;
                text-align: center;
                margin: 8px 8px;
                position: relative;
                display: inline;
                float: left;
                overflow: hidden;
                font-size: 1em;
                border: 1px solid #e6e6e6;
                table{
                    height: 109px;
                    width: 100%;
                    text-align: center;
                }
                img {
                    width: 70px;
                    height: 74px;
                }
                p {
                    &.log {
                        position: relative;
                        top: -45px;
                    }
                    &.title {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        text-align: center;
                        bottom: 0;
                        background-color: #01b7ef;
                        color: white;
                        font-weight: bold;
                        padding: 2px 0;
                    }
                    &.progress {
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        height: 8px;
                        overflow: hidden;
                        z-index: 50;
                        span {
                            display: none;
                            overflow: hidden;
                            width: 0;
                            height: 100%;
                            background: #1483d8 url(/public/plugin/uploader/progress.png) repeat-x;

                            -webit-transition: width 200ms linear;
                            -moz-transition: width 200ms linear;
                            -o-transition: width 200ms linear;
                            -ms-transition: width 200ms linear;
                            transition: width 200ms linear;

                            -webkit-animation: progressmove 2s linear infinite;
                            -moz-animation: progressmove 2s linear infinite;
                            -o-animation: progressmove 2s linear infinite;
                            -ms-animation: progressmove 2s linear infinite;
                            animation: progressmove 2s linear infinite;

                            -webkit-transform: translateZ(0);
                        }
                    }
                    &.imgWrap {
                        position: relative;
                        z-index: 2;
                        line-height: 70px;
                        vertical-align: middle;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;

                        -webkit-transform-origin: 50% 50%;
                        -moz-transform-origin: 50% 50%;
                        -o-transform-origin: 50% 50%;
                        -ms-transform-origin: 50% 50%;
                        transform-origin: 50% 50%;

                        -webit-transition: 200ms ease-out;
                        -moz-transition: 200ms ease-out;
                        -o-transition: 200ms ease-out;
                        -ms-transition: 200ms ease-out;
                        transition: 200ms ease-out;
                    }

                    &.error {
                        background: #f43838;
                        color: #fff;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 28px;
                        line-height: 28px;
                        width: 100%;
                        z-index: 100;
                    }
                }
                .success {
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 40px;
                    width: 100%;
                    z-index: 200;
                    background: url(/public/plugin/uploader/success.png) no-repeat right bottom;
                }
            }

            .image-operate {
                position: absolute;
                height: 100%;
                filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#80000000', endColorstr='#80000000') \0
            ;
                background: rgba(0, 0, 0, 0.5);
                width: 100%;
                top: 0;
                left: 0;
                overflow: hidden;
                z-index: 300;
                span {
                    width: 100%;
                    display: block;
                    overflow: hidden;
                    padding: 8px;
                    margin: 5px 1px 1px;
                    cursor: pointer;
                    i{
                        color: white;
                    }
                }
            }
        }

        .statusBar {
            padding: 0 7px;
            vertical-align: middle;
            position: relative;
            line-height: 30px;
            .progress {
                border: 1px solid #1483d8;
                width: 198px;
                background: #fff;
                height: 18px;
                position: relative;
                display: inline-block;
                text-align: center;
                line-height: 20px;
                color: #6dbfff;
                position: relative;
                margin-right: 10px;

                span.percentage {
                    width: 0;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: #1483d8;
                    position: absolute;
                }
                span.text {
                    position: relative;
                    z-index: 10;
                }
            }

            .info {
                display: inline-block;
                font-size: 1em;
                color: #666666;
            }

            .btns {
                position: relative;
                line-height: 26px;
                overflow: hidden;
                .webuploader-pick, .uploadBtn {
                    background: #ffffff;
                    border: 1px solid #cfcfcf;
                    color: #565656;
                    padding: 0 18px;
                    display: inline-block;
                    border-radius: 3px;
                    margin-right: 7px;
                    cursor: pointer;
                    font-size: 1em;
                    float: left;
                }

                .uploadBtn {
                    background: #00b7ee;
                    color: #fff;
                    border-color: transparent;
                    &:hover {
                        background: #00a2d4;
                    }
                    &.disabled {
                        pointer-events: none;
                        opacity: 0.6;
                    }
                }

            }
        }
    }

    .filePicker2 {
        display: inline-block;
        float: left;
    }

    .doc-bg {
        background-size: 109px 93px !important;
        background-position-y: 8px !important;
        background-repeat: no-repeat;
        @each $doc-type in $doc-types {
            &.#{$doc-type} {
                background-image: url("/public/img/#{$doc-type}.png") !important;
            }
        }

        @each $doc-type in gif jpg jpeg bmp png {
            &.#{$doc-type} {
                background-image: url("/public/img/Default.png") !important;
            }
        }



    }
}

