/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
@import "variable";
@import "mixin";
.jgp-tree {
    position: relative;
    .tree-content-wrap {
        .root {
            position: relative;
            height: 22px;
            line-height: 22px;
            text-align: left;
            a > span {
                display: inline-block;
            }

        }
    }

    ul, li {
        list-style: none;
        text-align: left;
        position: relative;
    }
    ul {
        padding-left: 6px;
        margin-left: 6px;
        &.aux-line{
            &:after {
                border-left: 1px dashed gray;
                content: "";
                position: absolute;
                width: 1px;
                top: 0;
                left: 0;
                bottom: 10px;
            }
        }
    }
    .node-wrap {
        position: relative;
        white-space: nowrap;
        > i.y-line{
            border-left: 1px dashed gray;
            content: "";
            position: absolute;
            width: 1px;
            top: 0;
            left: -6px;
            height: 11px;
        }
        > i.x-line {
            position: absolute;
            border-bottom: 1px dashed gray;
            width: 5px;
            display: block;
            margin-left: 6px;
            top: 10px;
            left: -12px;
        }

        a > span {
            display: inline-block;
        }

        a.tree-node{
            .node-current-click{
                background-color: #45bcb86e;
                padding: 0 5px;
                color: #1c8480;
                font-weight: bold;
            }
            &.always-tools{
                span.tree-tool{
                    display: inline-block;
                    margin-right:10px;
                }
            }

            &.hover-tools{
                span.tree-tool{
                    display: none;
                }
                &:hover{
                    span.tree-tool{
                        display: inline-block;
                    }
                }
            }

        }
    }
    input[type=checkbox] {
        margin: auto;
        position: absolute;
        visibility: hidden;
        span {
            position: relative;

        }
        & + label {
            position: relative;
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid #45bcb8;
        }

        &:checked + label:after {
            content: "";
            position: absolute;
            left: 1px;
            top: -2px;
            width: 9px;
            height: 4px;
            border: 2px solid #45bcb8;
            border-top-color: transparent;
            border-right-color: transparent;
            -ms-transform: rotate(-60deg);
            -moz-transform: rotate(-60deg);
            -webkit-transform: rotate(-60deg);
            transform: rotate(-45deg);
        }

        &:checked.half-selected + label:after {
            -ms-transform: rotate(0);
            -moz-transform: rotate(0);
            -webkit-transform: rotate(0);
            transform: rotate(0);
            border: 2px solid #45bcb8;
            width: 4px;
            background-color: #45bcb8;
            top: 2px;
            left: 2px;
        }
    }

    i.fa {
        display: block;
        width: 14px;
        height: 14px;
        text-align: center;
        color: #45bcb8;
    }

}
