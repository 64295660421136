/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
@import "variable";
@import "mixin";

.jgp-div {
    height: 100%;
    width: 100%;
    .row {
        width: 100%;
        /*overflow: hidden;*/
        .col {
            display: inline-block;

            .aux {
                min-width: 30px;
                display: block;
                height: 100%;
                width: 100%;
                text-align: center;
                border: 1px dashed #7e8c8d;
                span {
                    word-wrap: break-word;
                    color: #7e8c8d;
                }
            }
        }
    }
}

.jgp-layout-border {
    width: 100%;
    height: 100%;
    background: $LAYOUT_BACKGROUND_COLOR;
    position: relative;

    .middle {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        .east, .west, .center {
            position: absolute;
            top: 0px;
            height: 100%;
        }
        .east {
            right: 0px;
        }
    }

}

.jgp-layout-border-item {
    &.north, &.south {
        position: absolute;
        width: 100% !important;
        clear: both;
        z-index: 1;
        -moz-box-shadow: 0px 1px 10px $LAYOUT_SHADOW_COLOR;
        -webkit-box-shadow: 0px 1px 10px $LAYOUT_SHADOW_COLOR;
        box-shadow: 0px 1px 10px $LAYOUT_SHADOW_COLOR;
    }
    &.north {
        top: 0;
        right: 0;
        left: 0;
    }
    &.center {
        z-index: 1;
    }
    &.south {
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.west, &.east {
        .holder-wrap {
            /*width: $LAYOUT_HOLDER_SIZE;
            height: 80px;*/
            position: absolute;
            top: calc((100% - 80px) / 2);
            background-color: white;
            cursor: pointer;
            display: none;
            &.active {
                z-index: 1;
                display: block;
            }
            border: 1px solid #d8d8d8;
        }
        &:hover .holder-wrap {
            display: block;
        }
    }

    &.west {
        z-index: 3;
        .holder-wrap {
            left: 0px;
            border-radius: 0px 5px 5px 0px;
            box-shadow: gainsboro 0px 1px 9px;
            border-left: none;
            i.fa {
                padding: 20px 6px 23px 6px;
            }
            &.active {
                left: -10px;
                &:hover {
                    left: 0px;
                }
            }
        }

    }
    &.east {
        .holder-wrap {
            right: 0px;
            &.active {
                left: -10px;
            }
        }
    }
}
