/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
@import "variable";
@import "mixin";

.jgp-mask {
    .mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $MASK_BG_COLOR;
        z-index: 0;
    }
    .jgp-dialog {
        position: absolute;

        > .move-mask{
            position: absolute;
            top: $PANEL_TOP_HEIGHT;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $MASK_BG_COLOR;
            z-index: 9999;
        }

        > .jgp-panel {
            position: absolute;
            top:0;
            left:0;
            right: 0;
            bottom: 0;
            box-shadow: 0 0 8px #9a9999;
            -moz-box-shadow: 0 0 8px #9a9999;
            -webkit-box-shadow: 0 0 8px #9a9999;
            filter: progid:DXImageTransform.Microsoft.Shadow(color=#9a9999, direction=120, strength=4);
            border-radius: 4px;
            > .top {
                background-color: $DIALOG_TOP_BG_COLOR !important;
                .title {
                    color: white!important;
                    .big {
                        font-weight: bold!important;
                    }
                }

                .jgp-tools {
                    .button {
                        font-weight: bold;
                        color: white!important;
                        &:hover, &:focus {
                            background-color: rgba(120, 144, 156, 0.82)!important;
                        }
                    }
                }
            }
        }
    }
    .jgp-loading {
        table.loading {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            text-align: center;
            tr td {
                font-size: 2em;
                i {
                    color: white;
                }
            }
        }
    }

    .jgp-alert {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .button{
            font-size: 12px;
            font-weight: bold;
        }
        .alert {
            position: absolute;
            top: 20%;
            background-color: white;
            .html-inset * {
                white-space: nowrap;
            }
        }
        .jgp-panel {
            .top {
                * {
                    color: white
                }
            }
            .html-inset {
                table tr:nth-child(2) {
                    height: 30px;
                }
                p {
                    white-space: normal;
                    font-size: 0.8em;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
            &.error {
                .top {
                    background-color: $STATE_DANGER_COLOR;
                    .button:hover, .button:focus {
                        background-color: $STATE_DANGER_COLOR_BORDER;
                    }
                }
            }
            &.warn {
                .top {
                    background-color: $STATE_WARNING_COLOR;
                    .button:hover, .button:focus {
                        background-color: $STATE_WARNING_COLOR_BORDER;
                    }
                }
            }
            &.info {
                .top {
                    background-color: $STATE_SUCCESS_COLOR;
                    .button:hover, .button:focus {
                        background-color: $STATE_SUCCESS_COLOR_BORDER;
                    }
                }
            }
        }
    }

}
