/**
 * 项目   jgp-front-pc
 * 作者   loufei
 * 时间   2018/10/11
 */
@import "variable";
@import "mixin";

.jgp-adv-site {
    overflow: hidden;
    @extend .no-select;
    display: inline-block;
    min-width: 100%;
    table{
        padding: 0;
        margin: auto;
        &.left{
            .site-item{
                @include rotate(180);
                .image{
                    @include rotate(0);
                }
            }
            @include rotate(180);
            margin-right: 0;
        }

        &.right{
            .image{
                @include rotate(180);
            }
            margin-left: 0;
        }

        &.top{
            @include rotate(180);
            .num,.des{
                @include rotate(180);
            }
        }

        &.bottom{
        }
    }


    .site-item{
        height: 45px;
        width: 50px;
        margin: 3px;
        text-align: center;
        border: 1px dotted #adabab;
        position: relative;
        cursor: pointer;

        .image {
            height: 100%;
            width: 100%;
            display: block;
            background-repeat: no-repeat;
            background-size: 15px 15px;
            background-position: center;
            background-image: url('/public/adv/site/site-default.png');

            position: absolute;
            top:0px;
            bottom:0px;
            @include rotate(-90);
        }

        &.status-1 {
            .image{
                background-image: url('/public/adv/site/site-status-1.png');
            }

        }
        &.status-2 {
            .image{
                background-image: url('/public/adv/site/site-status-2.png');
            }
        }

        .num{
            position: absolute;
            top:-3px;
            left:0;
            width: 100%;
            font-size: 0.8rem;
            @include text-ellipsis();
            white-space:nowrap;
        }
        .des{
            position: absolute;
            bottom:-3px;
            left:0;
            width: 100%;
            font-size: 0.8rem;
            @include text-ellipsis();
            white-space:nowrap;
        }

        &.selected{
            background-color: #e2ece2;
        }
    }



}
