/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/12
 */
@import "variable";
.jgp-pager{
    > table{
        height: 100%;
        tr{
            td{
                padding: 0 2px;
                .button.current{
                    background-color: $GRID_MAIN_COLOR;
                    color: white;
                    font-weight: bold;
                }
                .num{
                    margin: 0 10px;
                }
            }

        }
    }
}
