/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
@import "variable";
@import "mixin";

.jgp-tree3 {
    display: inline-block;
    .jgp-tree-item3 {
        cursor: pointer;
        @extend .no-select;
        position: relative;
        ul {
            padding-left: 23px;
            > li.jgp-tree-item3.dot-line {
                &:before, &:after {
                    content: "";
                    left: -10px;
                    position: absolute;
                    right: auto;
                    border-width: 1px;
                }
                &:before {
                    border-left: 1px dashed #999;
                    height: 100%;
                    top: 0px;
                    width: 1px;
                }
                &:after {
                    border-top: 1px dashed #999;
                    height: 100%;
                    @include calc('top', '#{$TREE_LINE_HEIGHT} / 2', 'false');
                    left: -9px;
                    width: 10px;
                }
                &:last-child:before {
                    @include calc('top', '-#{$TREE_LINE_HEIGHT} / 2', 'false');
                    height: $TREE_LINE_HEIGHT;
                }
            }
        }

        &.root:before {
            content: "";
            border-left: none;
            bottom: unset;
            height: unset;
            top: unset;
            width: unset;
        }

        .tree-line-node {
            line-height: $TREE_LINE_HEIGHT;
            padding: 0 5px;
            margin: 5px 0;
            box-sizing: content-box;
            -moz-box-sizing: content-box; /* Firefox */
            -webkit-box-sizing: content-box; /* Safari */
            border: 1px solid transparent;
            word-spacing: normal;
            white-space: nowrap;
            > i, > span {
                font-weight: 100;
                display: inline-block;
            }

            &.checked {
                border: 1px dotted $TREE_CHECKED_COLOR;
                color: $TREE_CHECKED_COLOR;
            }
            &.half {
                span.text {
                    //color: $TREE_CHECKED_COLOR;
                }
            }

            .checked-icon, .half-icon, .empty-icon, .folder-open-icon, .folder-close-icon, .loading-icon,.icon {
                @extend .fa;
                margin: 0 5px;
            }

            .checked-icon {
                @extend .fa-check-square-o;
            }
            .half-icon {
                @extend .fa-check-square;
            }
            .empty-icon {
                @extend .fa-square-o;
            }
            .folder-open-icon {
                @extend .fa-plus-square-o;
            }
            .folder-close-icon {
                @extend .fa-minus-square-o;
            }
            .loading-icon {
                @extend .fa-spinner;
                @extend .fa-pulse;
                color: $TREE_LOADING_COLOR;
            }

            //复选框，展开状态框大小
            .check-icon, .folder-icon, .loading-icon {
                @extend .#{$TREE_ICON_SIZE};
            }

            .text {
                line-height: 1.3em;
                vertical-align: middle;
                font-size: 1.1em;
            }

            .tools {
                > button {
                    font-size: 1.1em !important;
                }
            }
        }

    }
    .bold {
        > span.text {
            //font-weight: bold;
        }
    }
    ul {
        line-height: $TREE_LINE_HEIGHT;
        list-style: none;
    }

}
