/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
@import "variable";
@import "mixin";
.jgp-panel {
    width: 100%;
    height: 100%;
    overflow: hidden;
    > .top {
        height: $PANEL_TOP_HEIGHT;
        background-color: $PANEL_TOP_BACKGROUND_COLOR;
        position: relative;

        table {
            width: 100%;
            height: 100%;
            td:first-child {
                max-width: 70%;
            }
            td:last-child {
                max-width: 30%;
            }
        }

        .title, .tools {
            display: table-cell;
            height: $PANEL_TOP_HEIGHT;
            vertical-align: middle;
            text-align: left;
        }
        .title {
            padding-left: 5px;
            .big, .small {
                float: left;
                clear: both;
            }
            .big {

            }
            .small {
                font-size: 0.8em;
                max-width: 100%;
                overflow: hidden;
            }
        }

        .tools {
            padding-right: 5px;
        }
    }
    > .content {
        background-color: $PANEL_BACKGROUND_COLOR;
        height: calc(100% - #{$PANEL_TOP_HEIGHT});
        width: 100%;
        overflow: hidden;
        iframe {
            width: 100%;
            height: 100%;
        }
        &.has-iframe {
            overflow: hidden;
        }
        .html-inset {
            height: 100%;
            width: 100%;
        }
        &.full {
            height: 100%;
        }
        > .scroll-content {
            height: 100%;
            width: 100%;
            position: absolute;
            .html-inset {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    &.max {
        position: fixed;
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        transform: unset!important;
        z-index: 1;

        .scroll-content {
            min-height: 100%;
        }
    }

}
