/**
 * 项目   skin
 * 作者   loufei
 * 时间   2018/5/5
 */
.jgp-btn{
    display: inline-block;
    &.button-tiny{
        font-weight: bold;
        height: 21px;
        line-height: 20px;
        padding: 0 5px;
    }
    &.button-small{
        font-weight: bold;
        height: 25px;
        line-height: 24px;
        padding: 0 20px;
    }
    &.button-normal {
        font-weight: bold;
        height: 37px;
        line-height: 35px;
        padding: 0 30px;
    }
    &.button-large {
        font-weight: bold;
        height: 45px;
        line-height: 44px;
        padding: 0 30px;
    }
    &.button-giant{
        font-weight: bold;
        height: 70px;
        line-height: 70px;
        padding: 0 70px;
    }
    &.button-circle{
        &.button-tiny{
            width: 21px;
        }
        &.button-small{
            width: 25px;
        }
        &.button-normal {
            width: 37px;
            i{
                margin-top: -5px;
            }
        }
        &.button-large {
            width: 45px;
            i{
                margin-top: -5px;
            }
        }
        &.button-giant {
            width: 70px;
            i{
                margin-top: -5px;
            }
        }
    }
}
