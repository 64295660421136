/**
 * 项目   jgp-front-pc
 * 作者   loufei
 * 时间   2018/9/9
 */
@import "variable";
@import "mixin";

.jgp-tab-panel {
    width: 100%;
    height: 100%;
    .tab-head, .tab-body {
        position: relative;
    }

    .tab-menu{
        position: fixed;
        z-index: 2;
        background-color: #e8e8e8;
        box-shadow: #bdbbbb 1px 1px 5px;
        color: #607D8B;
        .menu-item{
            padding:  2px 10px;
            cursor: pointer;
            &:hover{
                background-color: #cccccc;
            }
        }
    }

    .tab-head {
        height: 42px;
        background: #f8f7ee;
        box-shadow: inset 0 -2px 3px rgba(203, 196, 130, 0.06);
        .scroll-content-wrap{
            height: 100%;
            .scroll-content{
                height: 100%;
            }
            .scrollbar-track{
                display: none !important;
            }
        }

        .tab-head-item-wrap{
            height: 100%;
            display: inline-block;
            .tab-head-item {
                float: left;
                height: 100%;
                display: inline-table;
                padding: 0 15px;
                position: relative;
                > span, > a {
                    display: table-cell;
                    cursor: pointer;
                }
                &.selected {
                    box-shadow: inset 0 2px 0 #369895;
                    background-color: white;
                    > span:not(.close) {
                        color: #29324e;
                        font-weight: 500;
                    }
                }

                &.disabled {
                    > span, > a {
                        display: table-cell;
                        cursor: no-drop;
                        color: #c3c2b9 !important;
                    }
                }

                .close-wrap {
                    width: 17px;
                    text-align: right;
                    vertical-align: text-top;
                    position: absolute;
                    right: 0;
                    .close {
                        cursor: default;
                        display: none;
                    }
                }

                &:hover {
                    .close {
                        display: inline-block;
                    }
                }
            }
        }
        .tab-head-tools {
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #f8f7ee;
        }
        .fixed-min-btn {
            opacity: 0.1;
            position: fixed;
            z-index: 9999;
            top: -60px;
            right: -60px;
        }
        .fixed-min-btn:hover {
            top: 0px;
            right: 0px;
            opacity: 1;
        }
    }
    .tab-body {
        @include calc('height', '100% - 42px');

        .jgp-tab {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            filter: alpha(opacity=0); /* IE */
            -moz-opacity: 0; /* 老版Mozilla */
            -khtml-opacity: 0; /* 老版Safari */
            opacity: 0; /* 支持opacity的浏览器*/
            &.selected {
                filter: alpha(opacity=1); /* IE */
                -moz-opacity: 1; /* 老版Mozilla */
                -khtml-opacity: 1; /* 老版Safari */
                opacity: 1; /* 支持opacity的浏览器*/
                z-index: 2;
            }
        }

    }
}
