/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/18
 */
@mixin calc($property, $expression,$important:'false') {
    @if ($important == 'true') {
        #{$property}: -moz-calc(#{$expression}) !important;
        #{$property}: -webkit-calc(#{$expression}) !important;
        #{$property}: calc(#{$expression}) !important;
    } @else if ($important == 'false') {
        #{$property}: -moz-calc(#{$expression});
        #{$property}: -webkit-calc(#{$expression});
        #{$property}: calc(#{$expression});
    }
}

@mixin generateCols($col) {
    #{'.layout-'+$col} {
        @for $i from 1 through $col {
            .col-#{$i} {
                width: 100%/$col*$i;
            }
        }
        .col-full{
            width: 100%;
        }
    }
}

@mixin text-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@mixin linear-gradient-y($from,$to){
    background: -moz-linear-gradient(top, $from 0%, $to 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
    background: -webkit-linear-gradient(top, $from 0%,$to 100%);
    background: -o-linear-gradient(top, $from 0%,$to 100%);
    background: -ms-linear-gradient(top, $from 0%,$to 100%);
    background: linear-gradient(to bottom, $from 0%,$to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='##{$from}', endColorstr='##{$to}',GradientType=0 );
}

@mixin rotate($deg){
    transform:rotate(#{$deg}deg);
    -ms-transform:rotate(#{$deg}deg); 	/* IE 9 */
    -moz-transform:rotate(#{$deg}deg); 	/* Firefox */
    -webkit-transform:rotate(#{$deg}deg); /* Safari 和 Chrome */
    -o-transform:rotate(deg); 	/* Opera */
}
