/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/9
 */
@import "variable";
@import "mixin";

html {
    overflow: auto;
}

@include generateCols(12);
@include generateCols(24);
.clearfix:after {
    content: "";
    display: block;
    clear: both;
}
.no-select {
    * {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently not supported by any browser */
    }
}

._transparent{
    filter:alpha(opacity=0); /* IE */
    -moz-opacity:0; /* 老版Mozilla */
    -khtml-opacity:0; /* 老版Safari */
    opacity: 0; /* 支持opacity的浏览器*/
}
